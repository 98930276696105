<template>
  <div class="codeMore w100">
    <!-- <div class="fs-20 fw-b">扫码开启更多精彩</div> -->
    <div class="mt-15 encode">
      <div class="en">
        <img class="w100 h100" src="@/assets/img/general/code.png">
      </div>
      <div class="text ml-29">
        <!-- <div class="fs-18 fw-b mb-6">章易签</div> -->
        <div class="fs-20 fw-b mb-6">扫码开启更多精彩</div>
        <div class="c-999 fs-12 mb-6">随时随地签署</div>
        <div class="c-999 fs-12 mb-6">支持丰富的签署场景</div>
        <div class="c-999 fs-12 mb-6">更安全、快捷</div>
        <div class="c-999 fs-12 mb-6 cu-p" @click="EnterClick">进入官网</div>
      </div>
    </div>
  </div>
</template>
<script setup>
const EnterClick = () => {
  window.open('http://yilanshuzi.cn/#/', '_blank');
}
</script>
<style lang="scss" scoped>
.codeMore {
  padding: 30px 30px 23px;
  background-color: #fff;
  height: 214px;
  margin-top: 12px;

  .encode {
    display: flex;
    align-items: center;

    .en {
      width: 125px;
      height: 125px;
      // border: 1px solid;
    }
  }
}
</style>